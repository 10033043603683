*{
    scrollbar-width: thin;
    scrollbar-color: rgba(86, 182, 231,0.5) transparent;
}
:root{
    scroll-behavior: auto;
    --blue: #4467EB;
    --white: #fff;
    --blue-rgb: 68, 103, 235;
    --light-blue: #7D0ABD;
    --light-blue-rgb: 125, 10, 189;
    --orange-rgb: 253, 126, 20; 
    --pink-rgb: 214, 51, 132;
}
body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(var(--light-blue-rgb), 0.1);
    background-color: #eee;
    background-attachment: fixed;
    &.loading-data {
        pointer-events: none;
        &::before {
            content: '';
            position: fixed;
            left: 0;
            background: linear-gradient(to left, var(--blue), var(--light-blue))!important; 
            width: 0;
            height: 3px;
            z-index: 9999;
            -webkit-animation: beforeloading 2s infinite ease-out;
                    animation: beforeloading 2s infinite ease-out;
        }
        &::after {
            content: '';
            position: fixed;
            left: 0;
            background: linear-gradient(to left, var(--blue), var(--light-blue))!important; 
            width: 0;
            height: 3px;
            z-index: 9999;
            -webkit-animation: afterloadding 2s infinite ease-in;
                    animation: afterloadding 2s infinite ease-in;
        }
    }
}
body.loading-data::after{
    display: none !important;
}
@-webkit-keyframes beforeloading {
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}
@keyframes beforeloading {
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}
@-webkit-keyframes afterloadding {
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}
@keyframes afterloadding {
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}
.l{box-shadow: inset 0 0 0 1px red;}
::-webkit-scrollbar{width:4px;height:4px;background: transparent;}
::-webkit-scrollbar-track{border-radius:10px}
::-webkit-scrollbar-thumb{
    border-radius:10px;background-color: rgba($color: #56b6e7, $alpha: 0.5);
    &:hover{
        background-color: var(--blue);
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:-webkit-autofill:valid{
    background-image: inherit!important;
    box-shadow: 0 0 0 0px transparent inset !important;
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {background-color: transparent !important;}
.icon{
    stroke: unset;
    stroke-width: 0px;
    fill: currentColor;
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
}
.icon-h16{height: 16px!important} .icon-h17{height: 17px!important}
.icon-h18{height: 18px!important} .icon-h19{height: 19px!important}
.tscale-0p6{transform: scale(0.6)} .tscale-0p65{transform: scale(0.65)} 
.tscale-0p7{transform: scale(0.7)} .tscale-0p75{transform: scale(0.75)}
.tscale-0p8{transform: scale(0.8)} .tscale-0p85{transform: scale(0.85)} 
.tscale-0p9{transform: scale(0.9)} .tscale-0p95{transform: scale(0.95)}
.tscale-1{transform: scale(1)} .tscale-1p1{transform: scale(1.1)}
.tscale-1p2{transform: scale(1.2)} .tscale-1p3{transform: scale(1.3)}
.tscale-1p4{transform: scale(1.4)} .tscale-1p5{transform: scale(1.5)}

.fw-medium{font-weight: 500}
.fs-11{font-size: 11px}
.fs-12{font-size: 12px}
.fs-13{font-size: 13px}

.opacity-5{opacity: 0.05} .opacity-10{opacity: 0.10}
.opacity-15{opacity: 0.15} .opacity-20{opacity: 0.20}
.opacity-55{opacity: 0.55} .opacity-60{opacity: 0.60}
.opacity-65{opacity: 0.65} .opacity-70{opacity: 0.70}

.rounded-6{border-radius: 6px} .rounded-8{border-radius: 8px}
.rounded-10{border-radius: 10px} .rounded-15{border-radius: 15px}
.rounded-20{border-radius: 20px} .rounded-25{border-radius: 25px}

.mmw-60{min-width: 60px; max-width: 60px} .mmw-70{min-width: 70px; max-width: 70px}
.mmw-80{min-width: 80px; max-width: 80px} .mmw-100{min-width: 100px; max-width: 100px}

.minw-auto{min-width: auto} .minw-30{min-width: 30px}
.minw-35{min-width: 35px} .minw-36{min-width: 36px}
.minw-40{min-width: 40px} .minw-50{min-width: 50px}
.minw-60{min-width: 60px} .minw-65{min-width: 65px}
.minw-70{min-width: 70px} .minw-75{min-width: 75px}
.minw-80{min-width: 80px} .minw-90{min-width: 90px}
.minw-96{min-width: 96px} .minw-98{min-width: 98px}
.minw-100{min-width: 100px} .minw-110{min-width: 110px}
.minw-115{min-width: 115px} .minw-120{min-width: 120px}
.minw-125{min-width: 125px} .minw-130{min-width: 130px}
.minw-140{min-width: 140px} .minw-145{min-width: 145px}
.minw-150{min-width: 150px} .minw-160{min-width: 160px}
.minw-170{min-width: 170px} .minw-180{min-width: 180px}
.minw-190{min-width: 190px} .minw-200{min-width: 200px}
.minw-280{min-width: 280px} .minw-300{min-width: 300px}

.minh-18{min-height: 18px} .minh-19{min-height: 19px}
.minh-20{min-height: 20px} .minh-22{min-height: 22px}
.minh-40{min-height: 40px} .minh-45{min-height: 45px}
.minh-50{min-height: 50px} .minh-55{min-height: 55px}
.minh-150{min-height: 150px} .minh-200{min-height: 200px}
.minh-250{min-height: 250px} .minh-300{min-height: 300px}

.wh-25{width: 25px; height: 25px} .wh-26{width: 26px; height: 26px}
.wh-28{width: 28px; height: 28px} .wh-30{width: 30px; height: 30px}
.wh-40{width: 40px; height: 40px} .wh-45{width: 45px; height: 45px}
.wh-50{width: 50px; height: 50px} .wh-60{width: 60px; height: 60px}
.wh-100{width: 100px; height: 100px} .wh-150{width: 150px; height: 150px}

.maxw-35{max-width: 35px} .maxw-40{max-width: 40px}
.maxw-45{max-width: 45px} .maxw-50{max-width: 50px}
.maxw-55{max-width: 55px} .maxw-60{max-width: 60px}
.maxw-70{max-width: 70px} .maxw-80{max-width: 80px}
.maxw-100{max-width: 100px} .maxw-120{max-width: 120px}
.maxw-140{max-width: 140px} .maxw-160{max-width: 160px}
.maxw-180{max-width: 180px} .maxw-200{max-width: 200px}
.maxw-220{max-width: 220px} .maxw-280{max-width: 280px}
.maxw-300{max-width: 300px} .maxw-320{max-width: 320px}

.maxh-200{max-height: 200px} .maxh-250{max-height: 250px}
.maxh-300{max-height: 300px} .maxh-320{max-height: 320px}

.mmwh-40{min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px;}
.mmwh-160{
    min-width: 160px;
    min-height: 160px;
    max-width: 160px;
    max-height: 160px;
}

.h-2{height: 2px} .h-3{height: 3px} .h-4{height: 4px}
.h-5{height: 5px} .h-6{height: 6px} .h-7{height: 7px}
.h-8{height: 8px} .h-10{height: 10px}

.w80-h40{width: 80px; height: 40px}
.w100-h40{width: 100px; height: 40px}
.w78-h50{width: 78px; height: 50px}
.w80-h50{width: 80px; height: 50px}
.w100-h50{width: 100px; height: 50px}
.w120-h60{width: 120px; height: 60px}
.w120-h70{width: 120px; height: 70px}
.w120-h80{width: 120px; height: 80px}

.zindex-0{z-index: 0;}
.zindex-1{z-index: 1;}
.zindex-2{z-index: 2;}

.border-orange{border-color: var(--bs-orange)!important}
.border-pink{border-color: var(--bs-pink)!important}

.text-blue{color: var(--blue)}
.text-light-blue{color: var(--light-blue)}
.text-orange{color: var(--bs-orange)}
.text-pink{color: var(--bs-pink)}

.bg-white{background-color: var(--blue)}
.bg-blue{background-color: var(--blue)}
.bg-light-blue{background-color: var(--light-blue)}
.bg-blue-light-25{background-color: rgba(var(--light-blue-rgb), 0.25)}
.bg-blue-light-15{background-color: rgba(var(--light-blue-rgb), 0.15)}
.bg-info-light-15{background-color: rgba(var(--bs-info-rgb), 0.15)}
.bg-warning-light-15{background-color: rgba(var(--bs-warning-rgb), 0.15)}
.bg-danger-light-15{background-color: rgba(var(--bs-danger-rgb), 0.15)}
.bg-primary-light-15{background-color: rgba(var(--bs-primary-rgb), 0.15)}
.bg-secondary-light-15{background-color: rgba(var(--bs-secondary-rgb), 0.15)}
.bg-success-light-15{background-color: rgba(var(--bs-success-rgb), 0.15)}
.bg-orange-light-15{background-color: rgba(var(--orange-rgb), 0.15)}
.bg-pink-light-15{background-color: rgba(var(--pink-rgb), 0.15)}

.objfit-cover{object-fit: cover}
.objpos-center{object-position: center}

.ps-50{padding-left: 50px} .ps-70{padding-left: 70px}

.text-truncate * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

input[type="range"]{outline: none;}
.form-check-input {
    &:checked{
        background-color: var(--light-blue);
        border-color: var(--light-blue);
    }
    &:focus{
        border-color: rgba(var(--light-blue-rgb), 0.75);
        box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35);
    }
}
a {
    color: var(--light-blue);
    &:hover{color: var(--blue)}
}
.input-group-pill{
    >.btn, >.form-control, >.form-select, >.input-group-text {
        border-radius: 5rem;
    }
}
.input-group-rounded{
    >.btn, >.form-control, >.form-select, >.input-group-text {
        padding: 0.5rem 1rem 0.55rem 1rem;
        font-size: 1.18rem;
        border-radius: 5rem;
    }
}
.btn-group-lg>.btn, .btn-lg {
    padding: 0.5rem 1rem 0.55rem 1rem;
    font-size: 1.18rem;
}
.btn-primary{
    &.disabled, &:disabled{
        background-color: var(--blue);
        border-color: var(--blue);
    }
}
.btn-primary {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
    &:hover{
        background-color: var(--light-blue);
        border-color: var(--light-blue);
    }
    &:hover{
        border-color: rgba(var(--light-blue-rgb), 0.75);
    }
    &:focus{
        border-color: rgba(var(--light-blue-rgb), 0.75);
        box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35);
    }
    &:active:focus{
        box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35);
    }
}
.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, 
.btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #fff;
    background-color:var(--blue);
    border-color: var(--blue);
    box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.50);
}
.btn-active{
    color: #fff;
    background-color: var(--light-blue);
    border-color: var(--light-blue);
    &:hover{
        color: #fff;        
    }
}

.form-control, .form-select{
    &:hover{
        border-color: rgba(var(--light-blue-rgb), 0.75);
    }
    &:focus {
        color: var(--blue);
        background-color: #fff;
        border-color: rgba(var(--light-blue-rgb), 0.75);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35);
    }
}
.dropdown-item{
    &.active, &:active {
        background-color: var(--blue);
    }
}

.dropdown-item:hover {
    color: var(--blue);
    background: rgba(var(--light-blue-rgb), 0.25);
}

.modal-backdrop, .offcanvas-backdrop{
    &.show {
        opacity: .70;
    }
}
.offcanvas{
    &.offcanvas-w700{
        width: 700px;
    }
    &::before{
        content: '';
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: transparent;
    }
    .offcanvas-body{
        background: #fff;
    }
}


.rounded-pill-end-topbottom {
    border-top-right-radius: 40px!important;
    border-bottom-right-radius: 40px!important;
}
.image-uploading{
    position: relative;
    overflow: hidden;
    img{
        width: 120px;
        height: 120px;
        min-width: 120px;
        max-height: 120px;
        object-fit: cover;
        object-position: center;
        border: 1px solid #eee;
        border-radius: 10px;
    }
    input[type="file"]{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
    }
    .invalid-feedback{
        position: absolute;
        bottom: 14px;
        left: 136px;
    }
}
/* ===================================== */ 
/* Start | Inside Admin Dashabord Style */
.admin-header{
    top: 0;
    left: 250px;
    position: fixed;
    z-index: 100;
    width: calc(100% - 250px);
    height: 80px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #edf7fc;
    background-color: #eee;
    /*box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);*/
    .admin-notification{
        width: 40px;
        height: 40px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 24px;
        color: var(--light-blue);
        background-color: #fff;
        border-radius: 40px;
        position: relative;
        vertical-align: middle;
        transition: 350ms;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
        cursor: pointer;
        outline: none;
        border: none;
        &.active, &.show, &:hover{
            color: #fff;
            background-color: var(--light-blue);
        }
        span{
            position: absolute;
            background-color: var(--blue);
            color: #fff;
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            padding: 3px 5px 4px 5px;
            border-radius: 6px;
            top: -5px;
            right: -4px;
        }
    }
    .admin-profile{
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 14px;
        line-height: 1;
        padding: 0 2px 0 2px;
        background: transparent;
        outline: none;
        border: none;
        height: 40px;
        font-weight: 600;
        color: var(--blue);
        letter-spacing: 0.5px;
        background-color: #fff;
        border-radius: 50px;
        transition: 350ms;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
        &.show{
            background-color: var(--blue);
            color: #fff;
        }
        img{
            width: 36px;
            height: 36px;
            border-radius: 40px;
            display: block;
            object-fit: cover;
        }
    }
}
.admin-header-front{
    top: 0;
    left: 250px;
    z-index: 100;
    height: 80px;
    background-color: #edf7fc;
    background-color: #eee;
    .admin-profile{
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 14px;
        line-height: 1;
        padding: 0 2px 0 2px;
        background: transparent;
        outline: none;
        border: none;
        height: 40px;
        font-weight: 600;
        color: var(--blue);
        letter-spacing: 0.5px;
        background-color: #fff;
        border-radius: 50px;
        transition: 350ms;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
        &.show{
            background-color: var(--blue);
            color: #fff;
        }
        img{
            width: 36px;
            height: 36px;
            border-radius: 40px;
            display: block;
            object-fit: cover;
        }
    }
}
.admin-aside {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.10%);
    background-color: #fff;
    border-radius: 0;
    .admin-logobox {
        flex-grow: 0;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid rgba(var(--light-blue-rgb), 0.1);
        .admin-brandbox {
            text-decoration: none;
            img {
                width: 100%;
                display: block;
            }
        }
    }
    .admin-navbox {
        flex-grow: 1;
        padding: 15px 10px 80px 0px;
        overflow: hidden;
        overflow-y: auto;
        .accordion-item {
            border-radius: 0;
            border: none;
            background: transparent;
            h6{
                text-transform: uppercase;
                color: var(--light-blue);
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.5px;
                padding: 35px 10px 10px 15px;
                margin: 0;                
                position: relative;
            }
            .main-link {
                display: flex;
                text-decoration: none;
                align-items: center;
                white-space: nowrap;
                padding: 10px 15px;
                margin-bottom: 2px;
                border-radius: 0px 30px 30px 0;
                font-size: 15px;
                font-weight: 500;
                color: var(--blue);
                position: relative;
                transition: 350ms;
                &::before {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-top: 2px solid currentColor;
                    border-right: 2px solid currentColor;
                    top: calc(50% - 3px);
                    right: 15px;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: 300ms;
                }
                &[aria-expanded]::before {
                    opacity: 0.75;
                }
                &[aria-expanded="true"]::before {
                    top: calc(50% - 4px);
                    transform: rotate(135deg);
                }
                &:hover, &[data-currentpage="true"] {
                    background: rgba(var(--light-blue-rgb), 0.25);
                }
                &.active,
                &[aria-expanded="true"] {
                    background-color: var(--light-blue);
                    color: #fff;
                    [class*="bi-"] {
                        color: inherit;
                    }
                }
                [class*="icon"] {
                    width: 22px;
                    min-width: 22px;
                    height: 22px;
                    font-size: 22px;
                    line-height: 1;
                    margin-right: 14px;
                }
            }
        }
        .accordion-collapse {
            padding-left: 0px;
            font-size: 15px;
            .sub-link {
                display: block;
                text-decoration: none;
                padding: 8px 10px 8px 52px;
                margin-bottom: 2px;
                border-radius: 0 30px 30px 0;
                color: var(--blue);
                font-weight: 400;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: 350ms;
                &:last-child{
                    margin-bottom: 20px;
                }
                &::before{
                    content: '';
                    display: block;
                    top: calc(50% - 6px);
                    left: 20px;
                    border-radius: 40px;
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border: 2px solid rgba(var(--blue-rgb),0.6);
                }
                &:hover {
                    background: rgba(var(--light-blue-rgb), 0.15);
                }
                &.active,
                &:active {
                    background:  rgba(var(--light-blue-rgb), 0.25);
                    &::before{
                        background-color: rgba(var(--blue-rgb),0.30);
                        border: 2px solid rgba(var(--blue-rgb),0.75);
                    }
                }
            }
        }
    }
    .admin-copyrightsbox {
        flex-grow: 0;
        border-top: 1px solid rgba(var(--light-blue-rgb), 0.1);
        padding: 5px 10px 5px 10px;
        font-size: 10px;
        font-weight: 500;
        color: var(--blue);
        letter-spacing: 0.1px;
    }
}
.admin-wrapper {
    padding-top: 90px;
    padding-bottom: 30px;
    padding-left: 265px;
    padding-right: 15px;
    background: transparent;
    min-height: 100vh;
}
.page-title{
    color: var(--blue);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1px;

    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
.page-heading{
    color: var(--blue);
    position: fixed;
    top: 30px;
    left: 277px;
    z-index: 112;
    line-height: 1;
    padding-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1px;
}
.spinner-border-xs{
    width: 0.5rem;
    height: 0.5rem;
    border-width: 0.2em;
}

.table-common{
    padding: 1px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 8px;
    transition: 300ms;
    thead{
        tr{
            border-radius: 8px 8px 0 0 ;
            box-shadow: 0 0 0 1px rgba(var(--light-blue-rgb),0.2);
            th {
                font-size: 13.5px;
                letter-spacing: 0.25px;
                border: none;
                padding: 10px 14px;
                font-weight: 700;
                color: var(--blue);
                background-color: rgba(var(--light-blue-rgb), 0.15);
                margin-bottom: 4px;
                text-transform: uppercase;
                position: relative;
                &::before, &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 0;
                    height: 0;
                    right: 4px;
                    margin-top: -7px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 7px solid currentColor;
                    opacity: 0;
                }
                &::after{
                    margin-top: 2px;
                    border-bottom: 4px solid transparent;
                    border-top: 6px solid currentColor;
                }
                &[data-orderby]{
                    padding-right: 20px;
                    cursor: pointer;
                    &::before, &::after{
                        opacity: 0.25;
                    }
                }
                &.active{
                    &[data-orderby="asc"]{
                        &::before{
                            opacity: 0.75;
                        }
                    }
                    &[data-orderby="desc"]{
                        &::after{
                            opacity: 0.75;
                        }
                    }
                }
                &:first-child {
                    border-radius: 8px 0 0 0px;
                }
                &:last-child {
                    border-radius: 0 8px 0px 0;
                }
            }
        }
    }
    tbody{
        padding: 0 2px;
        tr {
            // border-radius: 6px;
            &:hover {
                box-shadow: 0 0 0 1px rgba(var(--light-blue-rgb),0.3);
                th, td{
                    // background-color: #f9f9f9;;
                    cursor: pointer;
                }
            }
            th, td {
                color: #444;
                border: none;
                padding: 10px 14px;
                font-size: 14.5px;
                transition: 300ms;
            }
            &:last-child{
                border-radius: 0 0 6px 6px;
                th, td {
                    &:first-child {
                        border-radius: 0px 0 0 6px;
                    }
                    &:last-child{
                        border-radius: 0 0px 6px 0;
                    }
                }
            }
        }
    }
}
.table-layout-fixed{
    table-layout: fixed;
}
.form-search{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 512 512'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: 12px 11px;
    padding-left: 35px;
    border-radius: 50px;
}
.btn-pageof{
    border: 1px solid #ced4da;
    padding-right: 26px;
    &::after{
        position: absolute;
        top: calc(50% - 0.075rem);
        right: 12px;
    }
    &:hover{
        border-color: rgba(var(--light-blue-rgb), 0.75);
    }
    &:focus{
        border-color: rgba(var(--light-blue-rgb), 0.75);
        box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35);
    }
}
.pagination{
    .page-item{
        &.active{
            pointer-events: none;
            .page-link {
                &, &:hover{
                    color: #fff;
                    background-color: var(--blue);
                    border-color: #dee2e6;
                }
            }
        }
        &.disabled{
            cursor: not-allowed;
        }
        .page-link {
            color: #444;
            min-width: 34px;
            height: 34px;
            text-align: center;
            padding: 0.23rem 0.25rem 0.28rem 0.25rem;
            font-size: 14px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            &:hover{
                color: #fff;
                background-color: var(--light-blue);
            }
        }
        &:first-child{
            .page-link {
                padding: 0.22rem 0.85rem 0.28rem 0.85rem;
                border-radius: 50px 0 0 50px;
            }
        }
        &:last-child{
            .page-link {
                padding: 0.22rem  0.85rem 0.28rem  0.85rem;
                border-radius: 0 50px 50px 0;
            }
        }        
    }
}
@media screen and (max-width: 575px){
    .pagination{
        position: relative;
        margin-top: 10px;
        margin-bottom: 2.5rem;
        .page-item{
            white-space: nowrap;
            &:first-child{
                position: absolute;
                top: 40px;
                width: 100px;
                left: calc(50% - 96px);
            }
            &:last-child{
                position: absolute;
                width: 100px;
                top: 40px;
                right: calc(50% - 96px);
                .page-link{
                    width: 100%;
                }
            }
            &:nth-child(2){
                .page-link{
                    border-radius: 20px 0 0 20px!important;
                }
            }
            &:nth-last-child(2){
                .page-link{
                    border-radius: 0 20px 20px 0!important;
                }
            }
        }
    }
}

.tooltip{
    &.show {
        opacity: 1;
        animation: tooltipanim 0.350s linear forwards;
    }
    &.bs-tooltip-top{
        .tooltip-arrow{ &::before{ border-top-color: var(--blue)}}
    }
    &.bs-tooltip-end{
        .tooltip-arrow{ &::before{ border-right-color: var(--blue)}}
    }
    &.bs-tooltip-bottom{
        .tooltip-arrow{ &::before{ border-bottom-color: var(--blue)}}
    }
    &.bs-tooltip-start{
        .tooltip-arrow{ &::before{ border-left-color: var(--blue)}}
    }
    .tooltip-inner {
        background-color: var(--blue);
        box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        padding: 10px 14px;
        font-size: 15px;
        opacity: 1;
    }
}
@keyframes tooltipanim{
    from{margin-top: 15px;}
    to{margin-top: 0px;}
}

.nav-pills-common{
    .nav-link{
        padding: 7px 20px;
        color: var(--blue);
        font-weight: 500;
        background-color: rgba(0, 0, 0, 0.05);
        &:hover{
            color: #fff;
            background-color: var(--light-blue);
        }
        &.active{
            background-color: var(--blue);
        }
    }
}

.dropdown-badge{
    .select-dropdown{
        min-height: 38px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &[data-placeholder]:empty::before{
            content: attr(data-placeholder);
            opacity: 0.70;
        }
        .badge{
            background: var(--blue);
            border-radius: 40px;
            font-weight: 500;
            border: none;
            padding: 0.27em 1.95em 0.4em 0.82em;
            margin: 2px;
            position: relative;
            overflow: hidden;
            strong{
                position: absolute;
                background-color: rgba(255,255,255,0.15);
                height: 100%;
                top: 0;
                right: 0;
                width: 1.2rem;
                padding-right: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Arial;
                line-height: 1;
                font-weight: 400;
                font-size: 14px;
                cursor: pointer;
                &:hover{
                    background-color: #da5252;
                }
            }
        }
    }
    .dropdown-menu{
        overflow: hidden;
        overflow-y: auto;
        max-height: 222px;
        &[data-types="multiple"]{
            .dropdown-item{
                &.active{
                    display: none;
                }
            }    
        }
        .dropdown-item{
            margin-bottom: 1px;
        }
        
    }
}
/*Start | Star Rating*/
.star-rating{
    display: inline-block;
    color: #ccc;
    font-family: Arial;
    vertical-align: top;
    position: relative;
    &::before{
        content:'\2605\2605\2605\2605\2605';
        font-family: inherit;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0px;
        text-shadow: 0 1px 0px #000;
    }
    span{
        display: block;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        white-space: nowrap;
        transition: 400ms;
        &::before{
            content:'\2605\2605\2605\2605\2605';
            position: relative;
            font-family: inherit;
            color: rgb(255,159,67);
            left: 0;
            top: 0;
            width: 100%;
            display: block;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0px;
        }
    }
}
/*End | Star Rating*/


/* Start | Toast Style */
.toast-end{
    .toast{
        letter-spacing: 0.4px;
        overflow: hidden;
        font-size: 15.5px;
        &.show {
            opacity: 1;
            animation: toastanim 0.350s linear forwards;
        }
        &.hide {
            display: block;
            opacity: 1;
            animation: toastanimreverse 0.350s linear forwards;
        }
        .bg-warning, .bg-light{
            .toast-body{
                color: #000 !important;
            }
            .btn-close{
                background-color: rgba(0,0,0,0.05);
                filter: invert(0);
                &:hover{
                    filter: invert(1);
                    background-color: rgba(4, 255, 255, 0.6);
                }
            }
        }
        .btn-close{
            background-color: rgba(255,255,255,0.05);
            filter: invert(1);
            opacity: 1;
            transition: 300ms;
            width: 22px;
            min-width: 22px;
            height: 22px;
            max-height: 22px;
            &:hover{
                background-color: rgba(4, 255, 255, 0.6);
            }
        }
    }
}
@keyframes toastanim{
    from{transform: translateX(300px)}
    to{transform: translateX(0px)}
}
@keyframes toastanimreverse{
    from{transform: translateX(0px)}
    to{transform: translateX(600px)}
}
/* End | Toast Style */

.overlay-onmobile{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}
.text-intwolines{
    display: -webkit-box;
    max-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.625;
}
.delay-half-second{
    opacity: 0;
    animation: delayinsecond 0.5s forwards;
}
.delay-one-second{
    opacity: 0;
    animation: delayinsecond 1s forwards;
}
@keyframes delayinsecond {
    0%, 80%{ opacity: 0}
    100%{ opacity: 1}
}


.img-hoveraction{
    img{
        transition: 350ms;
    }
    .img-showaction{
        opacity: 0;
    }
    &:hover{
        img{
            filter: grayscale(100%);
            opacity: 0.25;
        }
        .img-showaction{
            opacity: 1;
        }
    }
}



/*Start | Tablet + Mobile Style*/ 
@media (min-width: 575.90px){
    .w-sm-500{
        min-width: 500px;
    }
}

@media (max-width: 1199.90px){
    .admin-header{
        left: 0px;
        z-index: 100;
        width: 100%;
        height: 70px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .admin-aside{
        left: -280px;
        transition: 350ms;
    }
    .menu-active{
        .admin-aside{
            left: 0px;
        }
        .overlay-onmobile{
            visibility: visible;
            opacity: 1;
            transition: 350ms;
        }
    }
    .admin-wrapper {
        padding-top: 80px;
        padding-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .page-heading{
        left: 80px;
    }
}

@media (max-width: 767.90px){
    .page-heading{
        left: 0px;
        top: 0;
        z-index: 2;
        position: relative;
    }
}

@media (max-width: 574.90px){
    .admin-header{
        padding-left: 0px;
        padding-right: 0px;
    }
    .admin-wrapper{
        padding-left: 0px;
        padding-right: 0px;
    }
}
/*End | Tablet + Mobile Style*/ 

#root{
    .admin-wrapper{
        backface-visibility: visible;
        transform-origin: top;
        animation: pageanimation 350ms linear;
    }
}
@keyframes pageanimation{
    from{transform: translate3d(0, -30px, 0px); opacity: 0.1;}
    to{transform: translate3d(0, 0, 0px); opacity: 1;}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}
.calcType {
     white-space: normal !important;
     overflow: visible !important;
    text-overflow: ellipsis;
}
.mt-n1{
    margin-top: -1.2rem !important;
}
.plus-div{
    width: 9%;
    padding: 1.5%;
    padding-left: 2%;
    background-color: lightgray;
    display: inline;
    text-align: center;
    cursor: pointer;
}
.addOnText{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 0.575rem 0.75rem;
    cursor: pointer;
}
.rdt_Pagination{
    position: absolute;
    bottom: -50px;
    left: 0%;
}
.calculations-menu{
    height: 30rem;
    overflow-y: scroll;
}